import React from "react"

import {PageLayout} from "../ui/layout"

import "../styles/base.scss"


const Soon = () => {
  return (
    <PageLayout title="Coming Soon!">

    </PageLayout>
  )
}

export default Soon
